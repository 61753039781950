<template>
  <div class="root">
    <div v-if="comingsoon">
      <div class="mt-5 text-center">
        Coming soon
      </div>
    </div>
    <template v-if="!comingsoon">
      <h6 v-if="!pageValid && isMounted" class="text-center">
        Coming soon
      </h6>
      <div v-else-if="isMounted">
        <div class="text-center">
          <img
            style="width: 74%;"
            :src="require('@/assets/campaign-logo.webp')"
          />
        </div>
        <div class="banner mb-3" @click="imgRedirect">
          <img
            :src="require('@/assets/luckydraw_campaign_2024_03_14.jpg')"
            height="180px"
            width="100%"
          />
        </div>
        <div class="radius my-3" v-if="!expired">
          <button
            class="btn"
            @click="$router.push('/check-lucky-draws-campaign')"
          >
            ตรวจสิทธิ์การลุ้นรางวัลของคุณ
          </button>
        </div>
        <div class="radius my-3">
          <button
            class="btn"
            @click="$router.push('/announce-lucky-draws-campaign')"
          >
            ตรวจสอบผลประกาศรางวัล
          </button>
        </div>
        <div>
          <h6>Oishi Lucky Trip ลุ้นโชคทริปญี่ปุ่นกับโออิชิ</h6>
          <p>
            <b>รางวัล :</b> แพ็คเกจท่องเที่ยวญี่ปุ่นพร้อมเครื่องบินไปกลับ
            ประเทศญี่ปุ่น จำนวน 5 รางวัล รางวัลละ 2 ที่นั่ง มูลค่ารางวัลละ
            170,000 บาทมูลค่ารวม 850,000 บาท
          </p>

          <h6 class="mt-3">ระยะเวลาแคมเปญ</h6>
          <p>19 มีนาคม 2567 – 31 พฤษภาคม 2567</p>

          <h6 class="mt-3">กติกาการร่วมสนุก</h6>
          <ol style="padding-left: 16px;">
            <li>
              ซื้อ โออิชิขนาด 180มล. 350มล. 500มล. หรือชาคูลล์ซ่าขนาด 320มล.
              หรือจับใจขนาด 350มล. ครบ 1 ลัง รับ 1 สิทธิ์ ผ่าน Line Official
              Sermsuk Family
              เพื่อลุ้นรางวัลแพ็คเกจท่องเที่ยวญี่ปุ่นพร้อมเครื่องบินไปกลับ
              ประเทศญี่ปุ่น จำนวน จำนวน 5 รางวัล รางวัลละ 2 ที่นั่งมูลค่า 170,00
              บาทต่อรางวัลรวม 850,000 บาท
            </li>
            <li>
              ทำการจับฉลากรางวัลครั้งเดียวจำนวน 5 รางวัล วันที่ 6 มิถุนายน 2567
            </li>
            <li>
              ระยะเวลาในการรับสิทธิ์ชิงรางวัล เริ่ม 19 มีนาคม 2567 – 31 พฤษภาคม
              2567
            </li>
            <li>
              ผู้โชคดีต้องลงทะเบียนสมาชิกใน Line Official Sermsuk Family
              ตามวิธีการที่กำหนด และกดรับสิทธิ์ตามเงื่อนไขรายการ
            </li>
            <li>หมดเขตรับคูปอง 31 พฤษภาคม 2567</li>
            <li>
              ประกาศรายชื่อผู้โชคดีที่ไลน์ออฟฟิเชียล “Oishi Lucky Win”
              (@OishiLuckyWin)
            </li>
            <li>
              ผู้โชคดีที่ได้รับรางวัลมูลค่า 1,000 บาทขึ้นไป จะต้องชำระภาษี ณ
              ที่จ่าย 5% ของมูลค่ารางวัลที่ได้รับ
            </li>
            <li>
              ผู้โชคดีต้องติดต่อรับของรางวัลภายใน 30 วัน หลังจากประกาศผล
              มิฉะนั้นถือว่าสละสิทธิ์
            </li>
            <li>
              หากมีข้อพิพาท คำตัดสินของคณะกรรมการ ถือเป็นเด็ดขาดและสิ้นสุด
            </li>
          </ol>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
    isMounted: false,
    expired: false,
    award: false,
  }),
  async mounted() {
    window.loading(true);
    await this.fetchLuckydraw();
    await this.fetchAwards();
    window.loading(false);
    this.isMounted = true;
  },
  computed: {
    comingsoon() {
      return ![
        "โชห่วย",
        "โรงเรียน",
        "สถานศึกษา",
        "ของชำ,เบ็ดเตล็ด",
        "รถเข็น,แผงลอย",
        "วัด,สุสาน",
        "สถานีรถทัวร์",
        "รถไฟ",
      ].some((r) => this.shopCatName.includes(r));
    },
    pageValid() {
      return !this.expired || this.award;
    },
    shopCatName() {
      const { user_data } = this.$store.state;
      const shopCatName = user_data?.shopCatName;
      return shopCatName || "";
    },
  },
  methods: {
    imgRedirect() {
      // window.location = process.env.VUE_APP_LUCKYDRAW_EXTERNAL_URL;
    },
    async fetchLuckydraw() {
      const res = await fetch(
        `${process.env.VUE_APP_API_URL}/lucky-draws-campaign`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.$JWT}`,
          },
        }
      );
      switch (res.status) {
        case 204: {
          this.expired = true;
          return;
        }
        case 401:
          console.log("case 401");
          break;
        default: {
          console.log("no case");
          break;
        }
      }
    },
    async fetchAwards() {
      const res = await fetch(
        `${process.env.VUE_APP_API_URL}/lucky-draws-campaign/awards`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.$JWT}`,
          },
        }
      );
      switch (res.status) {
        case 200: {
          let data = await res.json();
          if (data.length > 0) this.award = true;
          return;
        }
        case 401:
          console.log("case 401");
          break;
        default: {
          console.log("no case");
          throw "no case";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.root {
  color: #2b2b2b;
  background-image: linear-gradient(167deg, #00c0ff, #98eeff 95%);
  min-height: 100vh;
  padding: 30px 20px 10px;
}

.radius {
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-style: solid;
  border-width: 3px;
  border-image-source: linear-gradient(to right, #fffadb 0%, #ceb783);
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #002fb1, #002fb1),
    linear-gradient(to right, #fffadb 0%, #ceb783);
  background-origin: border-box;
  background-clip: content-box, border-box;
  .btn {
    color: #fff;
    width: 100%;
  }
}
</style>
